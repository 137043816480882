import { render, staticRenderFns } from "./Loan-Product-Settings.vue?vue&type=template&id=6021497a&scoped=true&"
import script from "./Loan-Product-Settings.vue?vue&type=script&lang=js&"
export * from "./Loan-Product-Settings.vue?vue&type=script&lang=js&"
import style0 from "./Loan-Product-Settings.vue?vue&type=style&index=0&id=6021497a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6021497a",
  null
  
)

export default component.exports